import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import component from "./component.js";

Vue.config.productionTip = false;

import ElementUI from "element-ui";
import "@/assets/css/theme/index.css";
import "@/assets/css/base.scss";
Vue.use(ElementUI);

import axios from "./utils/axios";
Vue.prototype.axios = axios;

import GLOBAL from "./global";
Vue.prototype.$global = GLOBAL;

import CKEditor from "@ckeditor/ckeditor5-vue2";
Vue.use(CKEditor);

// 剪贴板
import VueClipBoard from "vue-clipboard2";
Vue.use(VueClipBoard);

import md5 from "js-md5";
Vue.prototype.$md5 = md5;

new Vue({
  router,
  store,
  component,
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
}).$mount("#app");

import { $yd } from "./utils/ydutils";
Vue.prototype.$yd = $yd;

Vue.prototype.$routerPush = function (param) {
  let _token = this.$yd.storage.get("_stoken");
  if (_token) {
    let shopId = _token.scope;
    if (param.path) {
      param.path = param.path.replace("$shopId", shopId);
    }
  }
  console.log("param.path", param.path);
  this.$router.push(param);
};

Vue.prototype.$routerOpen = function (param) {
  let _token = this.$yd.storage.get("_stoken");
  if (_token) {
    let shopId = _token.scope;
    if (param.path) {
      param.path = param.path.replace("$shopId", shopId);
    }
  }
  console.log("param.path", param.path);
  // this.$router.push(param);
  let routeData = this.$router.resolve(param);
  window.open(routeData.href, "_blank");
};

Vue.prototype.contactTextArr = function (arr1, arr2) {
  let resultArr = [];
  for (let i = 0; i < Math.max(arr1.length, arr2.length); i++) {
    // 判断当前索引下的元素是否存在于对应的数组中
    if (i < arr1.length) {
      resultArr.push(arr1[i]);
    }
    if (i < arr2.length) {
      resultArr.push(arr2[i]);
    }
  }
  return resultArr;
};

/** 下载二进制流文件
 * @param binFile 二进制文件流
 * @param fileName 文件名，例如：测试文本.txt
 * @param blobType Blob 对象的 type 属性给出文件的 MIME 类型，默认：'application/octet-stream'(用于通用二进制数据)
 */
Vue.prototype.downloadBinaryFile = function (binFile, fileName, blobType) {
  blobType = blobType || "application/octet-stream";
  // 处理二进制数据并创建 Blob 对象
  const blobObj = new Blob([binFile], { type: blobType });
  // 创建一个链接并设置下载属性
  const downloadLink = document.createElement("a");
  let url = window.URL || window.webkitURL || window.moxURL; // 兼容不同浏览器的 URL 对象
  url = url.createObjectURL(blobObj);
  downloadLink.href = url;
  downloadLink.download = fileName; // 设置下载的文件名
  // 将链接添加到 DOM 中，模拟点击
  document.body.appendChild(downloadLink);
  downloadLink.click();
  // 移除创建的链接和释放 URL 对象
  document.body.removeChild(downloadLink);
  window.URL.revokeObjectURL(url);
};

Vue.prototype.randomArray = function (length, min, max) {
  const arr = [];
  for (let i = 0; i < length; i++) {
    arr.push(Math.floor(Math.random() * (max - min + 1)) + min);
  }
  return arr;
};

// 计算相差天数
Vue.prototype.dateDifference = function (date1, date2) {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  const diff = d1 - d2;
  const days = Math.round(diff / (1000 * 60 * 60 * 24));
  return days;
};

String.prototype.camelUpperCase = function () {
  let str = this;
  return str.replace(/_(\w)/g, (match, char) => char.toUpperCase());
};

String.prototype.camelSnakeCase = function () {
  let str = this;
  return str.replace(/[A-Z]/g, (match) => "_" + match.toLowerCase());
};

String.prototype.parseUrlParam = function () {
  let url = this;
  let obj = {};
  let keyvalue = [];
  let key = "",
    value = "";
  let paraString = url.substring(url.indexOf("?") + 1, url.length).split("&");
  for (let item of paraString) {
    keyvalue = item.split("=");
    key = keyvalue[0];
    value = keyvalue[1];
    obj[key] = value;
  }
  return obj;
};

Vue.prototype.$routerBack = function () {
  this.$router.go(-1);
};

Vue.prototype.sleep = async function (time) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
};

Vue.prototype.base64ToFile = function (dataurl, filename = "file") {
  let arr = dataurl.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  // suffix是该文件的后缀
  let suffix = mime.split("/")[1];
  // atob 对经过 base-64 编码的字符串进行解码
  let bstr = atob(arr[1]);
  // n 是解码后的长度
  let n = bstr.length;
  // Uint8Array 数组类型表示一个 8 位无符号整型数组 初始值都是 数子0
  let u8arr = new Uint8Array(n);
  // charCodeAt() 方法可返回指定位置的字符的 Unicode 编码。这个返回值是 0 - 65535 之间的整数
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  // new File返回File对象 第一个参数是 ArraryBuffer 或 Bolb 或Arrary 第二个参数是文件名
  // 第三个参数是 要放到文件中的内容的 MIME 类型
  return new File([u8arr], `${filename}.${suffix}`, {
    type: mime,
  });
};

Vue.prototype.getFileBase64 = async function (file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    let fileResult = "";
    reader.readAsDataURL(file);
    //开始转
    reader.onload = function () {
      fileResult = reader.result;
    };
    //转 失败
    reader.onerror = function (error) {
      reject(error);
    };
    //转 结束  咱就 resolve 出去
    reader.onloadend = function () {
      resolve(fileResult);
    };
  });
};

/**
 * @param {Object} str 需要脱敏的字符串
 * @param {Object} begin 前面保留位数
 * @param {Object} end 后面保留位数
 */
Vue.prototype.desensitized = function (str, begin, end) {
  if (!str && begin + end >= str.length) {
    return "";
  }

  let leftStr = str.substring(0, begin);
  let rightStr = str.substring(str.length - end, str.length);

  let strCon = "";
  for (let i = 0; i < str.length - end - begin; i++) {
    strCon += "*";
  }
  return leftStr + strCon + rightStr;
};

import { formatDateTime } from "@/utils/index";

/**
 * 生成UUID
 */
Vue.prototype.uuid = function () {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "";

  var uuid = s.join("");
  return uuid;
};

// replaceAll
String.prototype.replaceAll = function (s1, s2) {
  return this.replace(new RegExp(s1, "gm"), s2);
};

// 全局注册一个日期格式化过滤器
Vue.filter("formatDateTime", function (d, s) {
  if (!d) return "-";
  return formatDateTime(d, s);
});

Number.prototype.formatdur = function () {
  if (this === null || this === 0) return "00:00:00";
  let timelength = this;
  if (timelength < 0) timelength = -timelength;
  let h = Math.floor(timelength / 3600) % 24;
  let m = Math.floor(timelength / 60) % 60;
  let s = Math.floor(timelength) % 60;
  h = h < 10 ? "0" + h : h;
  m = m < 10 ? "0" + m : m;
  s = s < 10 ? "0" + s : s;
  return `${h}:${m}:${s}`;
};

Number.prototype.formatdurMinute = function () {
  if (this === null || this === 0) return "00:00";
  let timelength = this;
  if (timelength < 0) timelength = -timelength;
  // let h = Math.floor(timelength / 3600) % 24;
  let m = Math.floor(timelength / 60) % 60;
  let s = Math.floor(timelength) % 60;
  // h = h < 10 ? "0" + h : h;
  m = m < 10 ? "0" + m : m;
  s = s < 10 ? "0" + s : s;
  return `${m}:${s}`;
};

/**
 * 字节数格式化
 * @returns
 */
Number.prototype.formatsize = function () {
  if (this === null || this === 0) return "0 B";
  const k = 1024;
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(this) / Math.log(k));
  let size = this / Math.pow(k, i);
  if (size > 1000) return size.toPrecision(4) + " " + sizes[i];
  else return size.toPrecision(3) + " " + sizes[i];
};

Date.prototype.format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "H+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};

Array.prototype.swap = function (index1, index2) {
  this[index1] = this.splice(index2, 1, this[index1])[0];
  return this;
};

File.prototype.getBlob = async function () {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.readAsArrayBuffer(this);
    reader.onload = (e) => {
      let data;
      if (typeof e.target.result === "object") {
        data = window.URL.createObjectURL(new Blob([e.target.result]));
      } else {
        data = e.target.result;
      }
      resolve(data);
      // this.imgBlob = data;
      // console.log(this.imgBlob);
    };
  });
};

String.prototype.underscoreToCamel = function () {
  return this.replace(/_\w/g, function (match) {
    return match[1].toUpperCase();
  });
};

String.prototype.firstLetterToLower = function () {
  return this.replace(/^./, (match) => match.toLowerCase());
};
