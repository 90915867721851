import axios from "axios"; // 引入axios
import QS from "qs"; // 引入qs模块，用来序列化post类型的数据
import storage from "./storage.js";
const CancelToken = axios.CancelToken;
var service = axios.create({
  timeout: 600000,
});
export default {
  // baseURL: "http://127.0.0.1:8080",
  baseURL: "https://api.ydleague.com",
  clientId: "e5cd7e4891bf95d1d19206ce24a7b321",
  checkCode(res) {
    switch (res.data.code) {
      case 401:
        window.requestLogin();
        break;
    }
    console.log("checkCode", res);
  },
  //getLogin请求
  getLogin(url, param) {
    url = this.baseURL + url;
    const params = JSON.parse(JSON.stringify(param || {}));
    const _ukit = storage.get("_ukit");
    if (_ukit) {
      params.u = _ukit.id;
    }
    const _skit = storage.get("_skit");
    if (_skit) {
      params.s = _skit.id;
    }
    let headers = {
      clientId: this.clientId,
    };
    const _token = storage.get("_stoken");
    if (_token) {
      headers.Authorization = "Bearer " + _token.access_token;
    }
    // console.log("_stoken", _token);
    return new Promise((resolve) => {
      service({
        method: "get",
        url,
        headers,
        params: params,
        cancelToken: new CancelToken(() => {}),
      })
        .then((res) => {
          //axios返回的是一个promise对象
          resolve(res.data); //resolve在promise执行器内部
        })
        .catch((err) => {
          console.log(err, "异常");
        });
    });
  },
  //get请求
  get(url, param) {
    url = this.baseURL + url;
    const params = JSON.parse(JSON.stringify(param || {}));
    const _ukit = storage.get("_ukit");
    if (_ukit) {
      params.u = _ukit.id;
    }
    const _skit = storage.get("_skit");
    if (_skit) {
      params.s = _skit.id;
    }
    let headers = {
      clientId: this.clientId,
    };
    const _token = storage.get("_stoken");
    if (_token) {
      headers.Authorization = "Bearer " + _token.access_token;
    }
    // console.log("_stoken", _token);
    return new Promise((resolve) => {
      service({
        method: "get",
        url,
        headers,
        params: params,
        cancelToken: new CancelToken(() => {}),
      })
        .then((res) => {
          //axios返回的是一个promise对象
          this.checkCode(res);
          resolve(res.data); //resolve在promise执行器内部
        })
        .catch((err) => {
          console.log(err, "异常");
        });
    });
  },
  //post请求
  post(url, param) {
    url = this.baseURL + url;
    const params = JSON.parse(JSON.stringify(param || {}));
    const _ukit = storage.get("_ukit");
    if (_ukit) {
      params.u = _ukit.id;
    }
    let headers = {
      "Content-Type": "application/json;charset=UTF-8",
      clientId: this.clientId,
    };
    const _token = storage.get("_stoken");
    if (_token) {
      headers.Authorization = "Bearer " + _token.access_token;
    }

    const _skit = storage.get("_skit");
    if (_skit) {
      params.s = _skit.id;
    }

    for (let i in params) {
      if (params[i] !== 0 && !params[i]) {
        delete params[i];
      }
    }

    return new Promise((resolve) => {
      service({
        method: "post",
        url,
        headers,
        data: params,
        cancelToken: new CancelToken(() => {}),
      })
        .then((res) => {
          this.checkCode(res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err, "异常");
        });
    });
  },
  //post请求
  postParam(url, param) {
    url = this.baseURL + url;
    const params = JSON.parse(JSON.stringify(param || {}));
    const _ukit = storage.get("_ukit");
    if (_ukit) {
      params.u = _ukit.id;
    }
    let headers = {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      clientId: this.clientId,
    };
    const _token = storage.get("_stoken");
    if (_token) {
      headers.Authorization = "Bearer " + _token.access_token;
    }

    const _skit = storage.get("_skit");
    if (_skit) {
      params.s = _skit.id;
    }

    for (let i in params) {
      if (params[i] !== 0 && !params[i]) {
        delete params[i];
      }
    }

    return new Promise((resolve) => {
      service({
        method: "post",
        url,
        headers,
        data: params,
        cancelToken: new CancelToken(() => {}),
      })
        .then((res) => {
          this.checkCode(res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err, "异常");
        });
    });
  },
  //post请求
  postBody(url, param) {
    url = this.baseURL + url;
    const params = JSON.parse(JSON.stringify(param || {}));
    const _ukit = storage.get("_ukit");
    if (_ukit) {
      params.u = _ukit.id;
    }
    let headers = {
      "Content-Type": "application/json",
      clientId: this.clientId,
    };
    const _token = storage.get("_stoken");
    if (_token) {
      headers.Authorization = "Bearer " + _token.access_token;
    }
    return new Promise((resolve) => {
      service({
        method: "post",
        url,
        headers,
        params: params,
        cancelToken: new CancelToken(() => {}),
      })
        .then((res) => {
          this.checkCode(res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err, "异常");
        });
    });
  },
  postForm(url, param) {
    url = this.baseURL + url;
    const params = JSON.parse(JSON.stringify(param || {}));
    const _ukit = storage.get("_ukit");
    if (_ukit) {
      params.u = _ukit.id;
    }
    let headers = {
      "Content-Type": "application/json",
      clientId: this.clientId,
    };
    const _token = storage.get("_stoken");
    if (_token) {
      headers.Authorization = "Bearer " + _token.access_token;
    }
    return new Promise((resolve) => {
      service({
        method: "post",
        url,
        headers,
        data: QS.stringify(params, { indices: false }),
        cancelToken: new CancelToken(() => {}),
      })
        .then((res) => {
          this.checkCode(res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err, "异常");
        });
    });
  },
  filePost: function (url, param) {
    console.log(param, "param");
    // const params = JSON.parse(JSON.stringify(param || {}));
    url = this.baseURL + url;
    let headers = {
      "Content-Type": "multipart/form-data;charset=UTF-8",
      clientId: this.clientId,
    };
    const _token = storage.get("_stoken");
    if (_token) {
      headers.Authorization = "Bearer " + _token.access_token;
    }
    return new Promise((resolve) => {
      service({
        method: "post",
        url,
        headers: headers,
        data: param,
        cancelToken: new CancelToken(() => {}),
      })
        .then((res) => {
          this.checkCode(res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err, "异常");
        });
    });
  },
};

// , { indices: false }
