<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    // document.documentElement.style.setProperty("--yd-primary", "#8558fa");

    window.requestLogin = () => {
      console.log("requestLogin");
      this.$message({
        message: "登录过期，请重新登录",
        type: "warning",
      });
      this.$router.push({
        path: "/loginplat",
      });
      // this.$bus.$emit("windowScroll", window.scrollY);
    };

    window.addEventListener("scroll", () => {
      this.$bus.$emit("windowScroll", window.scrollY);
    });

    window.addEventListener("resize", () => {
      console.log("windowResize");
      this.$bus.$emit("windowResize");
    });
  },
};
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
}
</style>
