import deepClone from "./deepClone.js";
import storage from "./storage.js";

export const $yd = {
  deepClone: deepClone,
  storage: storage,
  packageObject: (obj) => {
    let objClone = deepClone(obj);
    for (var key in objClone) {
      if (objClone[key] === 0 || objClone[key] === "") {
        console.log("deepClone");
      } else if (!objClone[key]) {
        delete objClone[key];
      }
      if (typeof objClone[key] === "object") {
        objClone[key] = JSON.stringify(objClone[key]);
      }
    }
    return objClone;
  },
  uuid: () => {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "";

    var uuid = s.join("");
    return uuid;
  },
};

// const install = (Vue) => {
//   // 只有vue，挂载到Vue.prototype才有意义，因为nvue中全局Vue.prototype和Vue.mixin是无效的
//   Vue.prototype.$rrj = $rrj;
// };

// export default {
//   install,
// };
