import axios from "../utils/axios";

// 注册用户
export function register(params) {
  return axios.post("/auth/sto/register", params);
}

// 用户登录
export function login(params) {
  return axios.post("/auth/sto/login", params);
}

// 获取验证码
export function sendMsg(params) {
  return axios.post("/auth/sto/msg", params);
}

// 登录店铺
export function loginShop(params) {
  return axios.post("/auth/sto/shop", params);
}

// 获取登录用户信息
export function getLoginInfo() {
  // return axios.get("/user/info/profile");
  return axios.getLogin("/user/info/profile");
}

// 获取登录用户信息
export function wechatLogin(appId, code) {
  return axios.post(`/wechat/wxmp/api/${appId}/getUserInfo?code=${code}`);
}

// 获取店铺列表
export function getShopList(params) {
  return axios.get("/store/shopInfo/list", params);
}

// 获取当前登录店铺
export function getShopInfo() {
  return axios.get(`/store/shopInfo/showShop`);
}

// 获取角色列表
export function getRoleList(params) {
  return axios.get("/store/shopRole/list", params);
}

// 创建店铺
export function addShop(params) {
  return axios.post("/store/shopInfo/create", params);
}

// 创建店铺
export function getRouters(params) {
  return axios.get("/store/menu/getRouters", params);
}

// 查询素材分类
export function getOssCategorys(params) {
  return axios.get("/resource/ossCategory/getCategorys", params);
}

// 新建素材分类
export function addOssCategory(params) {
  return axios.post("/resource/ossCategory/create", params);
}

// 修改素材分类
export function updateOssCategory(params) {
  return axios.post("/resource/ossCategory/update", params);
}

// 删除素材分类
export function deleteOssCategory(id) {
  return axios.post(`/resource/ossCategory/${id}/delete`);
}

// 上传素材
export function uploadOss(formData) {
  return axios.filePost("/resource/oss/upload", formData);
}

// 上传素材
export function uploadUserOss(formData) {
  return axios.filePost("/resource/oss/userUpload", formData);
}

// 查询素材列表
export function getSourceList(params) {
  return axios.get("/resource/oss/list", params);
}

// 查询单个文件信息
export function findOssDetail(ossId) {
  return axios.get(`/resource/oss/${ossId}/show`);
}

// 下载单个文件信息
export function downloadOss(ossId) {
  return axios.get(`/resource/oss/download/${ossId}`);
}

// 初始化分片上传
export function initPart(params) {
  return axios.post("/resource/oss/initPart", params);
}

// 上传分片
export function uploadPart(formData) {
  return axios.filePost("/resource/oss/uploadPart", formData);
}

// 结束分片上传
export function completePart(params) {
  return axios.post("/resource/oss/completePart", params);
}

// 结束分片上传
export function findOssSpace() {
  return axios.get("/resource/oss/space");
}

// 结束分片上传
export function uploadVod(ossId) {
  return axios.post(`/resource/oss/${ossId}/vod`);
}

// 远程链接文件存储
export function createOss(params) {
  return axios.post("/resource/oss/create", params);
}

// 查询
export function findOssDownloadRecords(params) {
  return axios.get("/resource/oss/record/list", params);
}

// 创建商品
export function createGoods(form) {
  return axios.post("/store/goods/spu/goods/create", form);
}

// 修改商品
export function updateGoods(id, form) {
  return axios.post(`/store/goods/spu/goods/${id}/update`, form);
}

// 删除商品
export function deleteGoods(ids) {
  return axios.post(`/store/goods/spu/${ids}/delete`);
}

// 上下架商品
export function setStatusGoods(ids, status) {
  return axios.post(`/store/goods/spu/${ids}/setStatus`, {
    saleStatus: status,
  });
}

// 创建商品分类
export function createGoodsCategory(form) {
  return axios.post("/store/goods/category/create", form);
}

// 查询商品分类
export function findGoodsCategorys() {
  return axios.get("/store/goods/category/tree");
}

// 查询商品分类
export function findGoodsAll(params) {
  return axios.get("/store/goods/category/all", params);
}

// 修改商品分类
export function updateGoodsCategorys(id, params) {
  return axios.post(`/store/goods/category/${id}/update`, params);
}

// 创建商品分组
export function createGoodsGroup(form) {
  return axios.post("/store/goods/group/create", form);
}

// 查询商品分组
export function findGoodsGroups() {
  return axios.get("/store/goods/group/tree");
}

// 修改商品分组
export function updateGoodsGroups(id, params) {
  return axios.post(`/store/goods/group/${id}/update`, params);
}

// 查询品牌列表
export function findBrandList(params) {
  return axios.get("/store/goods/brand/list", params);
}

// 查询品牌列表
export function findBrandOptions(params) {
  return axios.get("/store/goods/brand/all", params);
}

// 创建品牌
export function createBrand(params) {
  return axios.post("/store/goods/brand/create", params);
}

// 修改品牌
export function updateBrand(id, params) {
  return axios.post(`/store/goods/brand/${id}/update`, params);
}

// 删除品牌
export function deleteBrand(id) {
  return axios.post(`/store/goods/brand/${id}/delete`);
}

// 查询供应商列表
export function findSupplierList(params) {
  return axios.get("/store/goods/supplier/list", params);
}

// 查询供应商列表
export function findSupplierOptions(params) {
  return axios.get("/store/goods/supplier/all", params);
}

// 查询供应商
export function findSupplierDetail(id) {
  return axios.get(`/store/goods/supplier/${id}/show`);
}

// 创建品牌
export function createSupplier(params) {
  return axios.post("/store/goods/supplier/create", params);
}

// 修改品牌
export function updateSupplier(id, params) {
  return axios.post(`/store/goods/supplier/${id}/update`, params);
}

// 删除品牌
export function deleteSupplier(id) {
  return axios.post(`/store/goods/supplier/${id}/delete`);
}

// 查询商品详情
export function findGoodsDetail(id) {
  return axios.get(`/store/goods/spu/${id}/show`);
}

// 创建课程
export function createCourse(form) {
  return axios.post("/store/goods/spu/course/create", form);
}

// 课程目录
export function findCourseContents(id) {
  return axios.get(`/store/goods/spu/course/${id}/contents`);
}

// 创建课程内容
export function createCourseContent(params) {
  return axios.post(`/store/goods/spu/content/create`, params);
}

// 查询课程
export function findCourseList(params) {
  return axios.get("/store/goods/spu/list", params);
}

// 查询课程详情
export function findCourseDetail(id) {
  return axios.get(`/store/goods/spu/${id}/show`);
}

// 创建讲师
export function createTeacher(form) {
  return axios.post("/store/teacher/create", form);
}

// 查询讲师
export function findTeacherList(form) {
  return axios.get("/store/teacher/list", form);
}

// 创建标签
export function createTag(form) {
  return axios.post("/store/shop/tag/create", form);
}

// 查询标签
export function findTagList(params) {
  return axios.get("/store/shop/tag/list", params);
}

// 查询标签详情
export function findTagDetail(id) {
  return axios.get(`/store/shop/tag/${id}/show`);
}

// 删除标签
export function deleteTag(id) {
  return axios.post(`/store/shop/tag/${id}/delete`);
}

// 修改标签
export function updateTag(id, params) {
  return axios.post(`/store/shop/tag/${id}/update`, params);
}

// 创建圈子标签
export function createCommunityTag(form) {
  return axios.post("/store/community/tag/create", form);
}

// 查询圈子标签
export function findCommunityTagList(params) {
  return axios.get("/store/community/tag/list", params);
}

// 查询圈子标签
export function findCommunityAllTag(params) {
  return axios.get("/store/community/tag/all", params);
}

// 查询圈子标签详情
export function findCommunityTagDetail(id) {
  return axios.get(`/store/community/tag/${id}/show`);
}

// 删除圈子标签
export function deleteCommunityTag(id) {
  return axios.post(`/store/community/tag/${id}/delete`);
}

// 修改圈子标签
export function updateCommunityTag(id, params) {
  return axios.post(`/store/community/tag/${id}/update`, params);
}

// 创建学习圈
export function createCommunity(form) {
  return axios.post("/store/community/create", form);
}

// 查询学习圈
export function findCommunityList(params) {
  return axios.get("/store/community/list", params);
}

// 查询学习圈
export function findMyAllCommunity(params) {
  return axios.get("/store/community/myAllCommunitys", params);
}

// 查询学习圈详情
export function findCommunityDetail(id) {
  return axios.get(`/store/community/${id}/show`);
}

// 查询学习圈加入状态
export function checkCommunityMember(communityId) {
  return axios.get(`/store/community/member/${communityId}/checkMember`);
}

// 加入免费学习圈
export function joinCommunityMember(communityId) {
  return axios.post(`/store/community/member/${communityId}/join`);
}

// 创建学习圈动态
export function createCommunityDynamic(params) {
  return axios.post("/store/community/dynamic/create", params);
}

// 主题合集关联
export function createCommunityDynamicCollection(id, params) {
  return axios.post(`/store/community/dynamic/${id}/contactCollection`, params);
}

// 删除主题合集关联
export function deleteCommunityDynamicCollection(id, params) {
  return axios.post(`/store/community/dynamic/${id}/deleteCollection`, params);
}

// 查询学习圈合集
export function findCommunityCollectionList(params) {
  return axios.get("/store/community/collection/list", params);
}

// 创建学习圈合集
export function createCommunityCollection(params) {
  return axios.post("/store/community/collection/create", params);
}

// 查询学习圈文件数
export function findCommunityFileCount(communityId) {
  return axios.get(`/store/community/dynamic/${communityId}/findFileCount`);
}

// 查询学习圈动态
export function findCommunityDynamicList(params) {
  return axios.get("/store/community/dynamic/list", params);
}

// 查询动态详情
export function findCommunityDynamicDetail(id) {
  return axios.get(`/store/community/dynamic/${id}/show`);
}

// 删除动态
export function deleteCommunityDynamic(id) {
  return axios.post(`/store/community/dynamic/${id}/delete`);
}

// 查询动态评论
export function findCommunityDynamicComments(id, params) {
  return axios.get(`/store/community/dynamic/${id}/findComments`, params);
}

// 查询动态评论
export function findCommunityDynamicAllComments(id, params) {
  return axios.get(`/store/community/dynamic/${id}/findAllComments`, params);
}

// 发送评论
export function sendComment(id, params) {
  return axios.post(`/store/community/dynamic/${id}/comment`, params);
}

// 回复评论
export function replyComment(id, params) {
  return axios.post(`/store/community/dynamic/${id}/reply`, params);
}

// 点赞
export function sendPraise(id, params) {
  return axios.post(`/store/community/dynamic/${id}/praise`, params);
}

// 取消点赞
export function cancelPraise(id, params) {
  return axios.post(`/store/community/dynamic/${id}/unpraise`, params);
}

// 查询圈子动态时间选项
export function findCommunityMonths(id) {
  return axios.get(`/store/community/dynamic/${id}/months`);
}

// 查询顶部Label
export function findCommunityLabels(params) {
  return axios.get(`/store/community/label/all`, params);
}

// 创建导航
export function createNav(form) {
  return axios.post("/store/shop/nav/create", form);
}

// 查询导航下拉列表
export function findNavOptions(params) {
  return axios.get("/store/shop/nav/options", params);
}

// 查询导航
export function findNavList(params) {
  return axios.get("/store/shop/nav/list", params);
}

// 查询导航详情
export function findNavDetail(id) {
  return axios.get(`/store/shop/nav/${id}/show`);
}

// 删除导航
export function deleteNav(id) {
  return axios.post(`/store/shop/nav/${id}/delete`);
}

// 修改导航
export function updateNav(id, params) {
  return axios.post(`/store/shop/nav/${id}/update`, params);
}

// 查询订单列表
export function findOrderList(params) {
  return axios.get(`/pay/order/list`, params);
}

// 查询开关
export function findShopSwitchs(params) {
  return axios.get(`/store/shop/console/switchs`, params);
}

// 修改开关
export function updateShopSwitchs(params) {
  return axios.post(`/store/shop/console/batchUpdate`, params);
}

// 查询appids
export function getSphToken(appId) {
  return axios.get(`/wechat/wxsph/api/${appId}/getToken`);
}

// 查询appids
export function getSphGoodsList(appId) {
  return axios.get(`/wechat/wxsph/api/${appId}/goodsList`);
}

// 查询appids
export function findAppidsList(params) {
  return axios.get(`/wechat/appid/list`, params);
}

// 创建appid
export function createAppids(params) {
  return axios.post(`/wechat/appid/create`, params);
}

// 同步渠道商品
export function findCityList(appId, params) {
  return axios.get(`/wechat/wxsph/api/${appId}/cityList`, params);
}

// 同步渠道商品
export function asyncChannelGoods() {
  return axios.post(`/store/goods/channel/async`);
}

// 查询学员列表
export function findMemberList(params) {
  return axios.get(`/store/member/list`, params);
}

// 添加课程会员
export function importMember(params) {
  return axios.post(`/store/member/subscribe/import`, params);
}

// 查询课程会员列表
export function findCourseMemberList(params) {
  return axios.get(`/store/member/subscribe/list`, params);
}

// 推送日志
export function pushLog(params) {
  params.apptype = "web";
  params.host = window.location.host;
  return axios.post(`/store/log/push`, params);
}

// 查询赛事活动列表
export function findCompetitionList(params) {
  return axios.get(`/store/competition/list`, params);
}

// 查询赛事活动作品列表
export function findDrawList(params) {
  return axios.get(`/store/draw/list`, params);
}

// 查询随机机器人列表
export function findRandomRobotList(params) {
  return axios.get(`/user/info/randomRobots`, params);
}

// 发布作品
export function createDraw(params) {
  return axios.post(`/store/draw/create`, params);
}

// 刷新作品排行榜
export function refreshRank(params) {
  return axios.post(`/store/draw/refreshRank`, params);
}

// 给作品投票
export function sendDrawVote(id, userId) {
  return axios.post(`/store/draw/${id}/sendVote?userId=${userId}`);
}

// 创建短链接
export function createShortUrl(params) {
  return axios.post(`/store/shorturl/create`, params);
}

// 创建临时短链接
export function createTempShortUrl(params) {
  return axios.post(`/store/shorturl/createTemp`, params);
}

// 查询短链接
export function findShortUrl(key) {
  return axios.get(`/store/shorturl/${key}/findByKey`);
}
