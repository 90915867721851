import Vue from "vue";

import WebsiteTop from "@/components/normal/website-top";
Vue.component("website-top", WebsiteTop);

import WebsiteHead from "@/components/normal/website-head";
Vue.component("website-head", WebsiteHead);

import WebsiteSaasHead from "@/components/normal/website-saas-head";
Vue.component("website-saas-head", WebsiteSaasHead);

import WebsiteSlotHead from "@/components/normal/website-slot-head";
Vue.component("website-slot-head", WebsiteSlotHead);

import WebsiteFoot from "@/components/normal/website-foot";
Vue.component("website-foot", WebsiteFoot);

import NormalDialog from "@/components/normal/normal-dialog";
Vue.component("normal-dialog", NormalDialog);

import NormalDrawer from "@/components/normal/normal-drawer";
Vue.component("normal-drawer", NormalDrawer);

import NormalShare from "@/components/normal/normal-share";
Vue.component("normal-share", NormalShare);

const component = {};
export default {
  component,
};
